<template>
  <div
    style="
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    "
  >
    <slot></slot>
  </div>
</template>
