
import { defineComponent, ref, onMounted, watch, DefineComponent, Ref } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleBrPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEmptyFields, campHandleEveryoneIsTrue } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import CampModalCropImage, { ImgResultsType } from "@/components/CampModalCropImage/CampModalCropImage.vue";
import { CampCropImageLoader, CampCropImageRack, CampUploadImgURL } from '@/components/CampModalCropImage';

interface IUserRoles {
  id: number,
  name: string,
  description: string
}

interface IUserData {
  cpf: string,
  name: string,
  email: string,
  tel: string,
  type: string,
  latestAccessCode: string,
  role: IUserRoles,
  url_media?: string | null,
  code_regional_internal_salesman?: string,
}

interface IPutUser extends Omit<IUserData, "role"> {
  id_user_roles: number
}

interface IUserResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserData
    errorCode: string
  },
  status: number
}

interface ICampModalCropImage {}

export const SALESMAN_IDS = [5]

export default defineComponent({
  name: "CompanyPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampCropImageLoader,
    CampCropImageRack,
    CampModalCropImage: CampModalCropImage as unknown as DefineComponent<{}, {}, ICampModalCropImage>
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore();
    const route = useRoute();
    const userImgURL = ref(CampUploadImgURL)
    const userImgIsLoading = ref(true)
    const userImgFile = ref<File | null>(null)
    const croppedImgReady: Ref<string | null> = ref(null)
    const userRolesModel = ref<null | IUserRoles[]>(null)
    const userModel = ref<null | IUserData>(null)
    const isSalesman = ref(false)
    const cpfUser = ref()
    const { id } = route.params;
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{
      cpf: boolean,
      mail: boolean,
      phoneNumber: boolean
    }>({
      cpf: false,
      mail: false,
      phoneNumber: false
    })

    const verifyUserExist = ref({
      isLoading: false,
      exist: false,
      start: false
    })
    async function onBlur(e) {
      const cpf = e.target.value
      if(!campHandleCPF(cpf)) {
        verifyUserExist.value.start = false
      }
      const cpfEvent = campHandleCPF(cpf, "unformatted")
      const cpfDiferent = campHandleCPF(cpfUser.value, "unformatted")
      if(cpf.length === 14 && campHandleCPF(cpf) && cpfDiferent != cpfEvent) {
        verifyUserExist.value.start = true
        verifyUserExist.value.isLoading = true
        try {
          const response = await axios.get(`/api/getVerifyExistUserCpf/${cpf}`)
          if(!response.data.data.userExist === false) {
            return verifyUserExist.value.exist = false
          }
          verifyUserExist.value.exist = true
        } catch (error) {
          
        } finally {
          verifyUserExist.value.isLoading = false
        }
      }
    }


    /** Handle User Image */
    function handleImgFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        userImgFile.value = file
      }
      fileInput.value = "";
    }

    function handleFileRemoval() {
      userImgIsLoading.value = false
      userImgURL.value = CampUploadImgURL
      croppedImgReady.value = null
      userModel.value!.url_media = null
    }



    function handleCroppedImgBase64(value: ImgResultsType) {
      userImgURL.value = value.croppedImgBase64
      croppedImgReady.value = value.croppedImgBase64
      // console.log("Cropped Img File: ", (value.croppedImgFile.size / 1024**2).toFixed(1) + "MB")
      // console.log("Thumbnail Img File: ", (value.thumbnailImgFile!.size / 1024**2).toFixed(1) + "MB")
    }

    /** Validate CPF */
    watch(() => userModel.value?.cpf, () => {
      if(userModel.value?.cpf) {
        isInvalidField.value.cpf = !campHandleCPF(userModel.value.cpf)
      }
    })

    /** Validate email */
    watch(() => userModel.value?.email, () => {
      if(userModel.value?.email) {
        isInvalidField.value.mail = !campHandleEmailVerification(userModel.value.email)
      }
    })

    /** Validate phone number */
    watch(() => userModel.value?.tel, () => {
      if(userModel.value?.tel) {
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(userModel.value.tel)
      }
    })

    /** Reset warning */
    watch(() => userModel.value, () => {
      isRequiredField.value = false
      isInvalidFieldWarning.value = false
    }, { deep: true })

    async function fetchData() {
      const urls = [
        `/api/getUserRoles`,
        `/api/getUserView/${id}`,
      ];
      try {
        loaderStore.open()
        const requests = urls.map(url => axios.get(url))
        const responses = await Promise.all(requests)
        if(!responses[0].data.error && !responses[1].data.error) {
          userRolesModel.value = responses[0].data.data.filter(el => el.level < 600)
          const { role, ...res } = responses[1].data.data
          userModel.value = {
            ...res,
            role: role ? role : { id: null, name: "" },
          }
          cpfUser.value = responses[1].data.data.cpf
          if(userModel.value?.url_media)
            userImgURL.value = userModel.value.url_media
        }
      } catch (error) {
        userRolesModel.value = null
        userModel.value = null
      } finally {
        loaderStore.close()
      }
    }

    async function onSubmitForm() {
      if(!userModel.value)
        return true

      const { cpf, tel, role, url_media, ...res } = userModel.value

      const putCpf = campHandleCPF(userModel.value.cpf, "unformatted").toString()
      if(!campHandleCPF(putCpf))
        return !userModel.value.cpf.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      const putTel = campHandleBrPhoneNumber(userModel.value.tel, "unformatted").toString()
      if(!campHandleBrPhoneNumber(putTel))
        return !userModel.value.tel.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      if(isInvalidField.value.mail)
        return !userModel.value.email.length ? isRequiredField.value = true : (isRequiredField.value = true, isInvalidFieldWarning.value = true)

      const putUser: IPutUser & { [key: string]: string | number | null } = {
        ...res,
        id_user_roles: role.id,
        cpf: putCpf,
        tel: putTel
      }
      const {latestAccessCode, code_regional_internal_salesman, ...newPutUser} = putUser
      if(campHandleEmptyFields(newPutUser))
        return isRequiredField.value = true

      try {
        loaderStore.open()
        const response: IUserResponse = await axios.put(`/api/putUser/${id}`, {
          ...putUser,
          url_media,
          base64imgData: croppedImgReady.value
        })
          showTimeAlert("Atualizado com sucesso!")
        return router.push("/usuarios")
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          }
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    const handleSalesmanLevel = () => {
      if(!(userModel.value?.role))
        return
      isSalesman.value = SALESMAN_IDS.includes(userModel.value.role.id)
    }

    watch(() => userModel.value?.role, handleSalesmanLevel, { deep: true })

    onMounted(() => (fetchData(), handleSalesmanLevel()))

    return {
      userImgURL,
      userImgIsLoading,
      CampUploadImgURL,
      handleImgFileChange,
      handleFileRemoval,
      userImgFile,
      handleCroppedImgBase64,
      userRolesModel,
      userModel,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      verifyUserExist,
      onBlur,
      isSalesman,
    }
  }
})
